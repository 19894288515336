.workspace_card:hover {
  background-color: #f6f6f6;
}

.content-group-table tbody tr:hover td {
  background-color: #f6f6f6;
}

.kanban-card:hover {
  background-color: #f6f6f6 !important;
}

.tui-full-calendar-weekday-grid-line:hover {
  background-color: #f6f6f6 !important;
}

.kanban-card.add-content_container:hover {
  background-color: darken(#F7F6FB, 2%) !important;
}

.kanban-board:hover {
  background-color: darken(#f4f1ec, 3%) !important;
}

.stack-table table td.add-content-column:hover {
  background-color: darken(#F7F6FB, 1%) !important;
}

.kanban-board {
  cursor: pointer;
}
