.layout {
  display: flex;
  flex-direction: column;
}

.layout__top-bar {
  z-index: 2;
}

.layout__login-logo {
  text-align: center;
  margin-bottom: 3rem;
}

.layout__body--authenticated {
  overflow-y: auto;
  flex-grow: 1;
}

label {
  margin-top: 0.8rem;
}

.modal .btn:disabled {
  background-color: #727580;
  border-color: #727580;
  opacity: 1;
}

.pr-0{
  padding-right: 0 !important;
}
