.action-icon {
  width: 18px;
  height: 18px;
  position: absolute;
}

.action-icon img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
}
