.content-label-popup {
  width: calc(14rem - 6px);
  position: absolute;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.2s ease,
  transform 0.2s ease,
  visibility 0.2s;
  z-index: 1024;
  top: 40px;

  &:not(.popup_no_limit_height){
    max-height: 400px;
  }

  &.popup_small{
    max-height: 300px;
  }

  .container-fluid{
    position: relative;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .btn-close{
    position: absolute;
    top: 12px !important;
    right: 10px;
    font-size: 14px;
  }

  .content-label-popup__title{
    font-size: 16px;
  }
}

.content-label-popup.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.content-label-popup .content-label-popup__list {
  margin-top: 8px;
  list-style-type: none;
}

.content-label-popup__list__mobile{
  max-height: 400px;
}

.content-label-popup .dropdown__close-button {
  float: right;
  margin-right: -4px;
  padding: 0;
}

.content-label-popup__add-button {
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  text-align: center;
  color: $body-color;
  background-color: #eee;
  cursor: pointer;
  font-size: 13px;
  border-radius: 5px;
  display: flex;
  justify-content: center; /* Align horizontal */
  align-items: center; /* Align vertical */
  height: 40px;

  @media (min-width: $large-breakpoint) {
    height: inherit;
  }
}

.content-label-popup__add-button:hover {
  color: $body-color;
  background-color: #c3c3c6;
}
