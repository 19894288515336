a.logo__text {
  margin-right: 1.5rem;
  font-size: 16px;
  font-weight: bold;
  color: #f7f7f7;
}

a.logo__text:hover {
  color: #ffffff;
}
