.stage__attribute-icon {
  display: inline-block;
  border-radius: 50%;
  width: 0.6rem;
  height: 0.6rem;
  margin-left: 4px;
  font-size: 0.7em;
  line-height: 1.42em;
  vertical-align: middle;
  text-align: center;
  background-color: #333;
  color: #fff;
  font-weight: bold;
}

.stage__attribute-icon--requires-approval {
  background-color: #ffc05f;
}

.stage__attribute-icon--visible-to-client {
  background-color: #68c5aa;
}
