.button-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.button-group .btn:first-child {
  margin-left: 0;
}

.button-group .btn {
  margin-left: 0.5rem;
}
