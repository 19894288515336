.user-dropdown {
  display: block;
  margin: 0;
  padding: 0;
  background-color: #fff;
  list-style: none;
}

.user-dropdown li {
  padding: 0.6em 1em;
  font-size: 0.9em;
  margin-bottom: 3px;
  cursor: pointer;
}

.user-dropdown li:hover {
  background-color: #e9eaf5;
}

.user-dropdown__user--active {
  background-color: #e9eaf5;
}

.user-dropdown__user-info-wrapper {
  display: inline-block;
  vertical-align: middle;
}

.user-dropdown__user-name {
  font-size: 1rem;
}

.user-dropdown__user-role {
  font-size: 0.9rem;
  color: #727580;
}

.user-dropdown img,
.user-dropdown .user-avatar {
  margin-right: 10px;
  vertical-align: middle;
}

.user-dropdown > li > button {
  border: 0px;
  padding: 0px;
  background: transparent;
  width: 100%;
  text-align: left;
}

#postion_top {
  position: absolute;
  bottom: 50px;
  width: 93%;
  z-index: 999;
}

#postion_bottom {
  position: absolute;
  top: 50px;
  width: 93%;
  z-index: 999;
}
