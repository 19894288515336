div.user-avatar {
  cursor: pointer;
  font-weight: 400;
  background-color: #fe543d;
  color: white;
  border-radius: 50%;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;

  div{
    position: absolute;
    top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
  }
}

.user-profile_container{
  position: absolute;
  transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
  z-index: 1025;
  visibility: hidden;
  width: calc(192px * 1.2);
  min-height: calc(216px * 1.2);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #ffffff;
  border: 1px solid rgba(244,241,236,0.5);
  overflow: hidden;

  &.active{
    visibility: visible !important;
  }

  .user-profile_image{
    width: 100%;
    height: calc(72px * 1.2);
    background-color: purple;
    color: white;
    font-size: 50px;
    text-transform: uppercase;
    position: relative;

    .avatar-container{
      position: absolute;
      bottom: -30px;
      left: 10px;
    }
  }

  .close-container{
    position: absolute;
    right: 0;
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 20px;
  }

  .user-profile_details{
    margin-right: 0;
    font-size: 12px;
    color: #2b2f3f;
    font-weight: 500;
    font-family: 'Inter';

    h4{
      font-family: 'Inter';
      font-weight: 700;
    }
  }

  .view_profile-button{
    width: 100%;
    text-align: center;
    color: #727580;
    font-size: 12px;
    font-weight: 500;
    padding: 0.5rem;
    border-radius: 5px;
    height: 36px;
    background-color: #f7f7fb;
    font-family: 'Inter';

    &:hover{
      cursor: pointer;
    }
  }

  .user-profile_details__roles {
    text-transform: capitalize;
  }
}

.user-status{
  height: 10px;
  width: 10px;
  border-radius: 5px;

  &.active{
    background-color: $color-success;
  }
}
