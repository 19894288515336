.stage-dropdown{

  .dropdown-toggle{
    font-size: 1.5rem;
    padding: 0;
    background: none !important;
    border: none !important;
    color: #8D8E9D !important;
    position: relative;
    height: 40px;
    width: 40px;
  }

  .stage-dropdown__menu{
    padding: 6px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid rgba(244,241,236,0.5);

    .stage-dropdown__button{
      height: 36px;
      background-color: #f7f7fb;
      cursor: pointer;
      font-size: 14px;
      padding: 10px;

      &.stage-dropdown__button__delete{
        background: none;
        border-radius: 5px;
        color: #727580;

        &:hover{
          background-color: #f7f7fb;
        }
      }

      &.stage-dropdown__button__add,
      &.stage-dropdown__button__sort{
        margin-bottom: 6px;
        border-radius: 5px;
        color: #727580;
      }

      &.stage-dropdown__button__revision{
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &.stage-dropdown__button__approval{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 6px;
      }

      &:hover{
        background-color: #edeef7;
      }

      .svg-container{
        height: 17px;
        width: 17px;
        margin-right: 10px;
        display: inline-block;

        svg{
          width: 100%;
          height: 100%;
        }
      }

      .form-switch{
        margin-left: 2rem;

        .form-check-input{
          border: 2px solid #9c9fab;

          &:not(:checked){
             background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(156,159,171, 0.8)'/></svg>");
          }
        }

        .form-check-input:checked{
          border-color: #65c0a6 !important;
          background-color: #65c0a6 !important;
        }
      }
    }
  }
}

.stage-list__dropdown,
.reminder__dropdown{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
