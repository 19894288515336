.message {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 4px 0px 5px;
  font-size: 14px;
  line-height: 19px;
  border-radius: 4px;
  color: #444;
  border: 0px solid #fe543d;
  transition: .5s;
  background-color: #ededed;

  &.hovered, &.highlighted{
    background-color: #E2E2E2;
  }
}

.clearfix.hovered{
  .message{
    background-color: #E2E2E2;
  }
}

.message.message--other .description,
.message.message--own .description {
  display: inline-block;
  padding-bottom: 10px;
  border-radius: 5px;
  text-align: left;
  padding-right: 0;
  padding-left: 0;

  p {
    margin-bottom: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
  }

  img {
    max-width: 100%;
    max-height: 150px;
  }
}

.message .action-icon {
  //position: absolute;
  //top: -6px;
}


.message.message--other .action-icon,
.message.message--own .action-icon {
  //right: -36px;
}

.message.message--other .description.description--with-action,
.message.message--own .description.description--with-action  {
  margin-right: 40px;
  min-height: 24px;

  .icon-container{
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    img{
      height: 10px !important;
      width: 10px !important;
    }

    &.red{
      background-color: #FE543D;
    }

    &.green{
      background-color: #68C5AA;
    }

  }
}

.message.message--own,
.message.message--other {
  float: left;
  text-align: left;
}

.message .description-container .description {
  position: relative;
  word-break: break-word;

  span{
    display: inline-block !important;
  }
}

.message .description-container {
  .description__user{
    font-weight: 600;
  }
}

.message.message--own .description-container .description {
}

.message.message--own .description-container .description a,
.message.message--own .description-container .description a:hover,
.message.message--other .description-container .description a,
.message.message--other .description-container .description a:hover {
  font-weight: bolder;
}

.message.message--own .description-container .description a,
.message.message--own .description-container .description a:hover {
  color: #222;
}

.message.message--other .description-container .description a,
.message.message--other .description-container .description a:hover {
  color: #444;
}

.message.message--other,
.message.message--own {
  text-align: left;
}

.message.message--notification {
  text-align: center;
  width: 100%;
}

.message .message-date,
.message .message-user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message .message-date,
.message .message-edited {
  font-size: 11px;
  color: #8D8E9D;
}

.message .message-edited {
  margin-left: 4px;
}

.message .message-user {
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  width: 40px;
}

.message ol,
.message ul {
  margin-left: 1rem;
  margin-bottom: 21px;
}

.message li {
  text-align: left;
}

.message ul {
  list-style: disc;
}

.message ol {
  list-style: decimal;
}

.message-reply-button{
  color: #2B2F3F;
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  font-size: 11px;

  &:hover:not(.disabled){
    opacity: 0.5;
  }

  &.disabled{
    cursor: initial;
    opacity: 0.5;
  }
}

.attachments-container{
  //pointer-events: auto;
}

.attachment-container.file{
  border-radius: 6px;
  background: #F5F5FF;
  padding: 0.4rem;
  max-width: 100%;
}

.filename-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;

    .close-button{
      margin-left: 0.5rem;
      background: black;
      border-radius: 10px;
      height: 20px;
      width: 20px;
      color: white;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      display: flex
  ;

      i{
      }
    }

    &:not(.show_x_btn){
      &:hover{
        cursor: pointer;
        text-decoration: underline;
  
        .close-button{
          visibility: visible;
        }
      }
  
      .close-button{
        visibility: hidden;
      }
    }
}

.filename-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;

    i{
      margin-right: 10px;
    }
}

.filename-ext {
  white-space: nowrap;
}

.attachment-container.image{
  display: inline-block;

  &.dynamic{
    width: 30%;

    .attachment-thumbnail{
      aspect-ratio: 1/1;
      width: 100%;
      height: 100%;
    }
  }


  &.by_2{
    width: auto !important;
  }
}


.attachment-thumbnail {
  border-radius: 6px;
  height: 40px;
  width: 40px;
  position: relative;
  overflow: hidden;
  justify-content: center;
  display: flex;

  .close-button{
    background: black;
    border-radius: 10px;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    filter: drop-shadow(0.05em 0.05em orange);
    cursor: pointer;
  }

  &:not(.show_x_btn){
    &:hover{
      cursor: pointer;
  
      .close-button{
        display: block;
      }
    }
  
    .close-button{
      display: none;
    }
  }

  .img-fluid{
    object-fit: cover;
    width: 100%;
  }
}

.image-attachment-container{
  padding: 4px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  font-size: 14px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border: 1px solid #d5d5d5;
    overflow: visible;
  }

  &::-webkit-scrollbar-thumb:horizontal{
    background-color: #ededed;
    border-radius: 10px;
  }

  &:hover{
    filter: brightness(95%);
  }
}

.message.message--reply{
  background-color: #F5F5FF;
  color: #2B2F3F;
  padding: 3px 10px;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;

  &:hover{
    filter: brightness(95%);
  }

  i, .description_html, span {
    color: #727580;
    font-size: 12px;
  }

  .comment-to-reply-container{
    width: 100%;
    line-height: 1;

    .description_html{
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;

      * {
        display: inline;
        content: ' ';
        padding: 0 3px;
      }
    }
  }

  .user{
    font-weight: 500;
  }
}

.selected-reply-container{
    i{
      cursor: pointer;

      &:hover{
        opacity: 0.8;
      }
    }
}
