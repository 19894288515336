textarea {
  resize: none !important;
}

.btn.btn-success{
  background-color: #2ECDAB;
  color: #fff;
  &:hover {
	  border-color: #2ECDAB;
	  color: #fff;
	}
	&:active {
	  border-color: #2ECDAB;
	}
}

.btn.btn-info{
  background-color: #14D2E4;
  color: #fff;

  &:hover {
	  color: #fff;
	}
}
