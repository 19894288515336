#new_assigned_user_list {
  padding: 0;
  //max-height: 220px;
  overflow-y: auto;
}

.assigned-users-popup__container {
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;

  .assigned-users-popup__title{
    padding-left: 1rem;
    font-size: 16px;
  }
}

.assigned-users-popup__container .checkbox {
  padding: 0.5rem 1rem;
}

.assigned-users-popup__container .checkbox .dropdown__selected--with-check {
  position: absolute;
  top: 7px;
  right: 2px;
}

.assigned-users-popup__container .checkbox label {
  margin: 0;
}

.assigned-users-popup__container .checkbox input[type=checkbox] {
  display: none;
}

.assigned-users-popup__container .checkbox:hover {
  background-color: #e9eaf5;
}

.assigned-user-checkbox__name {
  overflow: hidden;
  margin-right: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 500;
}

.assigned-users-popup__container:not(.assigned-users-popup__modal) .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
}

.assigned-users-popup__container .submit_button {
  margin-top: 10px;
}

.assigned-users-popup__container .submit_button button {
  font-size: 0.9rem;
}

.assigned-users-popup__modal{
  .dropdown-item{
    padding: 0;
  }

  .user-avatar{
    height: 32px !important;
    width: 32px !important;
  }
  .assigned-user-checkbox__name{
    font-size: 14px;
  }

  .dropdown-item{
    margin-bottom: 1rem;
  }

  .dropdown__selected--with-check{
    right: 7px;
    top: 9 !important;

    img{
      width: 16px;
      height: 16px;
      top: 0;
      right: 0;
    }
  }
}
