.form-control.dropdown {
  padding: 0;
}

.form-control.dropdown .dropdown-menu {
  width: 100%;
}

.form-control.dropdown .dropdown-toggle {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 29px;
  font-weight: 400;
}

.form-control.dropdown .dropdown-toggle::after {
  position: absolute;
  top: 50%;
  right: 0.75rem;
}

.dropdown--with-check .dropdown-item {
  position: relative;
  //padding-right: 2.5rem;
}

.dropdown--with-check .dropdown__selected--with-check img {

  position: absolute;
  top: 10px;
  right: 0.5em;
  height: 0.8em;
}

.dropdown--with-tooltip{
  .selected-tooltip,
  .unselected-tooltip{
    //position: absolute;
    //right: 10px;
  }

  .selected-tooltip{
    //right: 30px;
  }

  .dropdown-toggle{
    padding-right: 1.5rem !important;
  }
}
.dropdown-item {
  &:hover,
  &:active {
    &.btn-success {
      background-color: darken($color-success, 5%);
    }

    &.btn-primary {
      background-color: darken($color-primary, 5%);
    }

    &.btn-danger {
      background-color: darken($color-danger, 5%);
    }

    &.btn-brand {
      background-color: darken($color-brand, 5%);
    }
  }
}

.dropdown-menu{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
