@import './variables';

.workspace_page-title{
  justify-content: space-between;
  background-color: #E9E8F5;
  width: 100%;
  margin: 0 !important;

  .workspace-bar__details-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .workspace-bar__users-wrapper {
    padding: 0.5rem;
  }

  .workspace_title {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    /* More style: */
    height: 50px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 18px;
    padding-right: 18px;

    @media (min-width: $large-breakpoint) {
      padding: 0.5rem;
    }

    .workspace_thumbnail_logo{
      max-width: 50px;
      max-height: 34px;
      border-radius: 6px;
      margin-right: 7px;
    }

    span{
      @media (min-width: $large-breakpoint) {
        //margin-left: 0.5rem;
      }
    }
  }

  .workspace_title h2 {
    font-weight: 700;
    font-size: 1rem;
  }

  .workspace_page-button-group{
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0.5rem;
    float: right;

    .btn{
      min-width: 100px;
      margin-right: 4px;
      font-size: 16px;
      border: none;
      background-color: white;
      color: #8D8E9D !important;

      &:hover {
        background-color: darken(#fff, 10%);
        color: darken(#8D8E9D, 10%);
      }

      &.active{
        font-weight: bold;
        color: #fff !important;
        background-color: #14D3E5;
      }

      &.active:hover {
        background-color: lighten(#14D3E5, 15%);
      }
    }
  }

  @media (max-width: $medium-breakpoint - 1px) {
    .workspace_page-button-group{
      float: left;
    }
  }
}

.workspace_page-title.mobile-container{

}

.workspace_page-show{

  @media (max-width: $large-breakpoint) {
  }
}

.mobile_navigation{
  height: 50px;
  background-color: #fff;
  display: none !important;

  @media (max-width: $large-breakpoint) {
    display: flex !important;
  }

  .mobile_navigation-button_group{
    display: flex;

    .btn{
      font-size: 10px;
      flex-grow: 1;
      color: black;
      border-radius: 5px;
      width: 0;

      svg{
        fill: black;
      }

      &:hover,
      &.active{
        background: rgba(254, 84, 61, 0.1) !important;
        color: $color-brand !important;

        svg{
          fill: $color-brand;
        }
      }

      .notification_icon-container{
        width: 24px;

        .unread_badge{
          top: 2px;
          right: 0px;
        }
      }
    }
  }
}
