.chat-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  bottom: 40px;
  right: 0;
  background-color: #f4f1ec;
  position: absolute;
  width: 400px;
  height: 90vh;
  border-radius: 3px;
  box-shadow: 0 1px 4px rgba(0, 0, 0,16);
  transition: opacity 0.5s;

  .chat-header {
    height: 37px;
    background-color: #2b2f3f;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: flex;
    align-items:center;
    padding: 0px 12px;
    justify-content: space-between;
  }

  .chat-body {
    height: 60vh;
    padding: 10px;
    overflow: auto;
  }

  .chat-composer {
    padding: 5px;
    background-color: #e9e8f5;
  }

  .chat-composer form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .chat-composer form .cursor-pointer {
    padding: 10px;
  }

  .chat-composer form div:not(.cursor-pointer) {
    flex-grow: 1;
  }
}

.submit_btn{
  min-width: 95px;

  &:hover{
    filter: brightness(85%);
  }
}

.dropdown-toggle.dropdown-toggle-split.btn{
  margin-left: 1.5px !important;

  &:hover{
    filter: brightness(85%);
  }
}
