.content-group-form {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.content-group-form .inline_edit_input {
  width: 100%;
  margin-bottom: 4px;
}

.content-group-form .form-error {
  bottom: 8px;
  right: 8px;
}
