@import '../variables';

#responsive-mobile .content-group-table.responsive-table {
  width: 100%;

  .no_queue_text{
    text-align: center;
  }

  @media (max-width: $large-breakpoint) {
    td{
      &:not(.add-content-column),
      .visible_div{
        min-height: 64px;
      }

      .col{
        padding-left: 1px;
      }
    }

    tr th:first-of-type{
      min-width: calc(100vw - 45px) !important;
      min-width: calc(100dvw - 45px) !important;
    }

    th{
      min-width: 260px;
    }

    .content_attachment_img{
      height: 85px;
      width: 85px;
    }

    .no_queue_text{
      text-align: left;
    }
  }
}

.content-group-table_container {
  padding: 0rem 17px;

  table{
    margin-bottom: 0rem;
  }

  @media (min-width: $large-breakpoint) {
    background-color: #e6e6e6;
    padding: 0.35rem 34px;
    margin-bottom: 1.5rem;

    table{
      margin-bottom: 1rem;
    }
  }
}

.content-group-table h2, {
  display: block;
  position: relative;
  margin-top: 0px;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  white-space: pre-wrap;
}

.content-group-table.table {
  border-collapse: initial;
}

.content-group-table .table > :not(caption) > * > * {
  background-color: #e6e6e6;
}

.content-group-table thead th {
  font-weight: 600;

  h2{
    max-width: 180px;
    word-wrap: break-word;
  }

  .inline_edit_input{
    display: block;
    margin-top: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 9px;
    padding-right: 9px;
    margin-left: 0px;
    line-height: 1.2;
    font-size: 1rem;
    border-radius: 5px;
    border: 0px;
    font-size: 1rem;
  }
}

.content-list-table tbody > tr > td {
  position: relative;
  font-size: 13px;
  border-color: #e6e6e6;
  border-width: 1px !important;

  @media (max-width: $large-breakpoint) {
    min-width: 190px;
  }

  .inline_edit_input{
    display: block;
    margin-top: 0px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 9px;
    padding-right: 9px;
    margin-left: 0px;
    line-height: 1.2;
    font-size: 1rem;
    border-radius: 5px;
    border: 0px;
    border: 1px solid $form-input-border;
    font-weight: bold;
    font-size: 1rem;
  }
}

.content-list-table thead > tr > th {
  vertical-align: middle;
}

.content-group-table .truncate {
  //margin-bottom: 4px;
}

.page-content-group-list__content-group-description{
  margin-bottom: 4px;
}

.content-group-table .user-thumbnails {
  flex-flow: wrap;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 6px;
}

.content-group-table__label {
  width: 10rem;
}

.content-group-table__participants {
  width: 10rem;
}

.content-group-table__due-date {
  width: 18rem;
}

.content-group-table__icon {
  display: inline-block;
}

.content-group-table__last-updated {
  width: 18rem;
}

.page-content-group-list__content-group-container{
  margin-left: 10px;

  .tag{
    margin-top: 5.5px;
  }
}

.page-content-group-list__content-group-title {
  display: -webkit-box;
  overflow: hidden;
  margin-top: 5.5px;
  font-size: 15px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  font-weight: 500;

  span{
    font-size: 13px;
    font-weight: initial;
  }
}

a.page-content-group-list__content-group-title {
  color: #000;
}

.content-group__readonly-stage {
  font-size: 13px;
}

.content-group-table .content__stage-dropdown{
  width: 160px;
}

.content-group-table .content__stage-dropdown .dropdown-toggle,
.content-group-table .content__stage-dropdown .dropdown-toggle:hover,
.content-group-table .content__stage-dropdown .dropdown-toggle:focus {
  font-size: 13px;
}

.content_group_note {
  font-size: 12px;
  font-weight: 100;
}

.title_validation {
	position: relative
}

#hide {
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre;
}

.inline_edit_input {
  padding: 0;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  font-weight: inherit;
}

.inline_date_date {
  border: 1px solid transparant;
  border-radius: 5px
}

.inline_edit_focus{
	border: 1px solid #00FFFF
}

.focus_inline_datepicker, .focus_inline_datepicker:focus, .focus_inline_datepicker:active, .focus_inline_datepicker:visited {
  border: 1px solid #00FFFF !important;
  border-radius: 5px;
}

.truncate{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
