.toastui-full-calendar-month-dayname-item{
  text-align: center;
}

.toastui-calendar-weekday-event{
  cursor: auto;
}

.toastui-full-calendar-month-dayname{
  border: none !important;
}

.toastui-calendar-grid-cell-date{
  font-size: 12px;
  color: #2b2f3f;
  font-weight: 500 !important;
}

.toastui-calendar-daygrid-cell{
  padding: 3px;
}

.toastui-calendar-weekday-resize-handle.toastui-calendar-handle-y{
  display: none;
}

.toastui-full-calendar-weekday-grid-date.toastui-full-calendar-weekday-grid-date-decorator{
  background-color: #fe543d !important;
}

.toastui-calendar-weekday-event-dot {
  display: none;
}

.toastui-calendar-template-time {
  font-size: 12px;
  font-weight: 600;
  font-family: "Inter";
  line-height: normal;

  .content-label__text{
    text-overflow: ellipsis;
    font-family: 'Inter';
    font-weight: 500;
    overflow: hidden;
    font-size: 15px;
    cursor: pointer;
    line-height: 15px;

    &.tagged{
      max-width: calc(100% - 58px);
    }

    &.with-draft{
      max-width: calc(100% - 75px);
    }
  }

  .desktop-calendar_card-info{
    position: relative;
    width: 100%;
  }

  .desktop-calendar_card-dueDate{
    position: absolute;
    right: 4px;
    top: 0;
    font-weight: normal;
  }

  .content-label__container{
    font-size: 10px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Inter";
    text-align: center;
    border: 0px solid #000000;

    .content-label{
    }
  }

  .content-label__close-button__container{
    .content-label__close-button{
    }
  }
}

.toastui-calendar-weekday-grid-date.toastui-calendar-weekday-grid-date-decorator.toastui-calendar-template-monthGridHeader{
  background-color: #fe543d !important;
}

.calendar__month{
  font-size: 20px;
  color: #2b2f3f;
  font-weight: 700;
  font-family: "Inter";
}

.calendar-button_group{
  .btn-today{
    width: 103px;
    height: 36px;
    border-radius: 4px;
    background-color: #f7f7fb;
    border: 0px solid #000000;
    padding: 0;
    font-family: "Inter";
    font-weight: 600;
    font-size: 15px;
  }

  .btn{
    background-color: #f7f7fb !important;
    color: #727580 !important;
    border: none !important;

    &:hover{
      color: #727580 !important;
    }

    &:not(.btn-today){
      height: 36px;
      width: 36px;

      i{
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}

.toastui-full-calendar-weekday-schedule.toastui-full-calendar-weekday-schedule-time .toastui-full-calendar-weekday-schedule-title {
}
.toastui-calendar-weekday-event-title {
  background-color: #E9E8F5;
  min-height: 60px;
  padding: 9px !important;
}

.toastui-full-calendar-month-dayname-item{
  font-weight: 500;
  font-size: 12px;
  color: #2b2f3f;

  .toastui-full-calendar-holiday-sun{
    color: #2b2f3f !important;
  }
}

.toastui-full-calendar-weekday-grid-line .toastui-full-calendar-weekday-grid-date{
  font-size: 12px;
  color: #2b2f3f;
  font-weight: 500 !important;
  font-family: 'Inter';
}


.toastui-calendar-weekday-event{
  height: auto !important;
  margin-left: 11px !important;
  margin-right: 10px !important;
  margin-top: 9px;
}

.toastui-calendar-see-more-container{
  min-height: 200px;
  height: auto !important;
  max-height: 265px;

  .toastui-calendar-see-more{
    max-height: 265px;
  }
}

.calendar-wrapper{
  .container{
    max-width: 100%;
    padding: 0;
  }
}

.MuiPickersToolbarText-root.Mui-selected,
.MuiPickersCalendarHeader-label,
.MuiMenuItem-root.Mui-selected,
.MuiPickersDay-root.Mui-selected,
.Mui-selected{
  font-weight: 900 !important;
}
