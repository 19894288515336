.modal-content {
  padding: 15px;
}

.modal-header {
  padding-bottom: 0 !important;
}

.modal-header .modal-title {
  font-size: 1.2em;
  font-weight: bold;
}

.modal-body {
  h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
}

.modal:not(.upgrade_modal){

  .modal-content{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
