.workspace-users-popup__pending-invitation-label {
  color: red;
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
}

.workspace-users-popup__pending-invitation-label:hover {
  text-decoration: underline;
}

.workspace-users-popup__name {
  font-size: 1rem;
  font-weight: 500;
}

.workspace-users-popup__email {
  color: #727580;
  font-weight: 500;

  @media (min-width: $large-breakpoint){
    line-height: 1;
  }
}

.workspace-users-popup .dropdown.form-control .dropdown-toggle:hover {
  background-color: #c7c8cc;
}
.workspace-users-popup .dropdown.form-control .dropdown-toggle {
  font-size: 0.85rem;
  font-weight: 500;
}

.workspace-users-popup .dropdown.form-control .dropdown-menu {
  padding-top: 4px;
  padding-bottom: 4px;
}

.workspace-users-popup .dropdown.form-control .dropdown-item {
  font-size: 0.8rem;
  font-weight: 500;
}

.workspace-users-popup{
  .avatar_container{
    height: 36px;
    width: 36px;
  }
}

.workspace-users-popup tr {
  position: relative;
}

.workspace-users-popup .col-lg-4:hover .workspace-users-popup__delete-user {
  display: block;
}

.workspace-users-popup__delete-user {
  display: none;
  position: absolute;
  top: 6px;
  right: -23px;
}

.selected-tooltip{
  position: absolute;
  top: 8px;
  left: -10px;
}

.workspace-users-popup__delete-user img {
  width: 14px;
  height: auto;
}
