$activity-page_multiplier: 0.95;

.activity-page_container {
  @media (min-width: 576px) {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    max-width: calc(540px * #{$activity-page_multiplier});
  }

  @media (min-width: 992px) {
    max-width: calc(960px * #{$activity-page_multiplier});
  }

  @media (min-width: 1200px) {
    max-width: calc(1140px * #{$activity-page_multiplier});
  }

  @media (min-width: 1400px) {
    max-width: calc(1320px * #{$activity-page_multiplier});
  }
}

.user-activities__table tr {
  border-width: 0;
}

.user-activities__table .notifications {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;

  @media (min-width: $large-breakpoint) {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  border-width: 0;
}

.user-activities__table a[data-contentid] {
}

.user-activities__table .notifications.read_message a[data-contentid]:not(.notification__actor-name) {
}

.user-activities__table .notifications.unread_message {
  background-color: #fff;
}

.user-activities__table .notifications:hover {
  background-color: #E9EAF5;
}
