@import '../variables';

.notifications-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 72px;
  top: 56px;
  max-height: calc(100vh - 70px);
  max-height: calc(100dvh - 70px);
  width: 400px;
  max-width: calc(100vw - 100px);
  max-width: calc(100dvw - 100px);
  overflow-y: auto;
  color: #2b2f3f;
  background-color: #fff;
  z-index: 1024;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 3px;
  transition: opacity 0.2s ease,
  transform 0.2s ease,
  visibility 0.2s;
  min-height: 170px;

  @media (max-width: $large-breakpoint) {
    top: 50px;
    height: calc(100vh - 100px);
    height: calc(100dvh - 100px);
    width: 100%;
    max-width: 100vw;
    max-width: 100dvw;
    left: 0;
    box-shadow: none;

    &.full_dropdown{
      max-height: calc(100vh);
      max-height: calc(100dvh);
      height: calc(100vh - 51px) !important;
      height: calc(100dvh - 51px) !important;
    }
  }

  .notifications-dropdown__header {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #dee2e6;

    @media (max-width: $large-breakpoint) {
      height: 68px;
      background: #F5F5F5;
      margin: 0;
      h5{
        font-size: 24px;
      }
    }
  }
}

.notification-backdrop {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100vh - 50px);
  height: calc(100dvh - 50px);
  display: none;

  @media (min-width: $large-breakpoint) {
    display: block;
  }
}

.notifications-dropdown__content {
  flex-grow: 1;
  overflow-y: auto;
}

.notifications-dropdown__action-link {
  float: right;
  margin-left: 0.5rem;
  font-size: 0.9rem;
}

.notifications-dropdown__action-link:hover {
  border-bottom: 1px dashed;
}

.notifications-dropdown__content .table {
  margin-bottom: 0;
  table-layout: fixed;
}

.notifications-dropdown__content .table tr:last-child {
  border-bottom-width: 0;
}

.notifications-dropdown__notification-timestamp {
  font-size: 0.78rem;
  color: lighten(#2b2f3f, 10%);
  letter-spacing: 0.5px;;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  .workspace_link{
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.notifications {
  background-color: white;
  color: #2b2f3f;
  padding: 0.5rem 1rem !important;

  @media (min-width: $large-breakpoint) {
    padding: 0.75rem 1rem !important;
  }

  &.read_message:not(.profile) {
    *:not(.notification__actor-name){
      font-weight: normal !important;
    }
  }

  &.unread_message {
    background-color: #E9EAF5;
    cursor: pointer;
  }

  &:hover a {
    border-bottom: 1px solid;
    color: #2b2f3f;
  }

  .notification__image-wrapper {
    flex-shrink: 0;
    flex-grow: 0;
    width: 44px;

    @media (min-width: $large-breakpoint) {
      padding-top: 4px;
    }
  }

  .notification-description_container{
    width: calc(100% - 44px);
    overflow-wrap: break-word;
  }

  a {
    color: #2b2f3f;
  }

  p.notification__actor-name {
    margin-bottom: 0px;
    font-size: 13px;
    font-weight: 600;
  }

  .notification__description {
    font-size: 14px;
  }

  @media (max-width: $large-breakpoint) {
    //height: 100px;

    .notification__description {
      //margin-bottom: 5px;
    }

    .notification__image-wrapper {
      width: 60px;

      .user-avatar,
      img{
        height: 48px !important;
        width: 48px !important;
      }

    }

    .notification-description_container{
      width: calc(100% - 60px);
      overflow-wrap: break-word;
    }

    .notification-info_container{
      height: 100%;
    }
  }
}

.unread_badge {
  position: absolute;
  right: 8px;
  top: 8px;
  background: #fe543d;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
}

.notification_options {

  .btn-read_switch{

    @media (max-width: $large-breakpoint) {
      .button-icon{
        height: 24px;
        width: 24px;
      }
    }
  }
  .notification_dropdwon {
     .btn {
      background: transparent !important;
      color: #787878 !important;
      border: 0px !important;
      padding: 0px;
      font-size: 13px;
    }
  }
  .read_options, .fa {
    font-size: 15px;
    font-weight: 400;
    color: #787878;
    cursor: pointer
  }

  .dropdown-menu {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid #ddd;
    min-width: 9rem;
    font-size: 13px;
    background-color: #fff
  }

  .notification_ul {
    list-style-type: none;
    text-align: center;

    li {
      a {
        padding: 5px 0px;
        cursor: pointer;
        color: #222;
        display: block;
        &:hover {
          background-color: #e9eaf5
        }
      }
    }
  }
}

.notification-link {
  width: 36px;
  height: 36px !important;
  justify-content: center;
}

.unread_badge {
  position: absolute;
  right: 8px;
  top: 8px;
  background: #fe543d;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
}

.notification_options {
  .notification_dropdwon {
     .btn {
      background: transparent !important;
      color: #787878 !important;
      border: 0px !important;
      padding: 0px;
      font-size: 13px;
    }
  }
  .read_options, .fa {
    font-size: 15px;
    font-weight: 400;
    color: #787878;
    cursor: pointer
  }

  .dropdown-menu {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: 1px solid #ddd;
    min-width: 9rem;
    font-size: 13px;
    background-color: #fff
  }

  .btn-read_switch{
    &:hover{
      background-color: #e9eaf5;
    }
  }

  .notification_ul {
    list-style-type: none;
    text-align: center;

    li {
      a {
        padding: 5px 0px;
        cursor: pointer;
        color: #222;
        display: block;

      }

      &:hover {
        background-color: #e9eaf5
      }
    }
  }
}

.all_notifiction_row {
  color: #8d8e9d;
  font-weight: 500;
  a {
    color: #8d8e9d;
    font-weight: 500;
    margin-right: 5px;
    font-size: 14px;
    &:hover {
      color: #8d8e9d;
    }
  }
}

.border_radius_100 {
  border-radius: 100px
}

.infinite-scroll-component__outerdiv{
  height: 80%;
}

.layout__container-wrapper--notifications{
  .infinite-scroll-component {
    overflow: initial !important;
  }

  @media (max-width: $large-breakpoint) {
    h2{
      font-size: 24px;
    }
  }

}
