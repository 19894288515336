@import '../variables';

.content-label__container{
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 4px;
  height: 24.5px;
  position: relative;

  &:hover{
    .content-label:not(.non-editable){
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .content-label__close-button__container{
      visibility: visible;
    }
  }
}

.calendar__project-labels{
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.project-label,
.content-label{
  height: 24.5px;
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 4px;
  position: relative;
  display: block;
  line-height: 16.5px;

  &:hover{
    filter: brightness(1.1);
    z-index: 2;
  }
}

.content-label__close-button__container{
  height: 24.5px;
  width: 24.5px;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  visibility: hidden;
  background-color: #f6f6f6;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  .content-label__close-button{
    height: 24.5px;
    width: 24.5px;
    position:relative;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    line-height: 24.5px;

    svg{
      height: 16px;
      width: 16px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
    }

    &:hover{
      filter: brightness(1.1);
    }
  }
}

.content-label-selector {
  display: flex;
  column-gap: 6px;
  margin-bottom: 8px;
}

.content-label-selector__checkbox {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-color: #777;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;

  @media (min-width: $large-breakpoint) {
    width: 18px;
    height: 18px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.content-label-selector__details {
  flex-grow: 1;
  border-radius: 5px;
  color: #111;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-left: 1em;
  padding-right: 1em;
  height: 32px;

  @media (min-width: $large-breakpoint) {
    font-size: 12px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    height: auto;
  }

  .edit-icon{
    display: none;
  }

  &:hover{
    filter: brightness(1.05);

    .edit-icon{
      display: block;
    }
  }
}

.content-label-selector__details img {
  display: inline-block;
  float: right;
  margin-top: 2px;
  vertical-align: text-bottom;
}
