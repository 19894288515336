.header__workspace-dropdown > .nav-item,
.header__workspace-dropdown > .nav-link {
  margin-right: 1rem;
}

.header__workspace-dropdown > .nav-item.dropdown .fa {
  margin-left: 0.2rem;
}

.header__workspace-dropdown > .nav-item.dropdown .dropdown-toggle__text {
  display: inline-block;
  max-width: 240px;
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header__workspace-dropdown .dropdown-menu {
  max-height: 280px;
  overflow-y: auto !important;
  border-radius: 6px;
  top: 4px !important;
  border: none;
}

.header__workspace-dropdown .dropdown-menu .dropdown-item {
  width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.9rem;
  line-height: 2.5rem;
  font-weight: 600;
}

.header__workspace-dropdown .dropdown-menu .dropdown-item:hover,
.header__workspace-dropdown .dropdown-menu .dropdown-item:focus {
  background-color: #E9E8F5;
  color: $body-color;
}

.header__workspace-dropdown .dropdown-menu .dropdown-item img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  border-radius: 4px;
}

.header__user-dropdown .dropdown-toggle {
  overflow: hidden;
}

a.notification-link {
  height: 50px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  vertical-align: middle;
}

a.notification-link i.fa {
  font-size: 20px;
}

a.notification-link.active {
  background-color: #555;
}

a.notification-link:hover {
  background-color: #555;
}

a.notification-link.active i.fa {
  color: #ffffff;
}
