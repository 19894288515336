.user-thumbnails {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  display: flex;
  justify-content: left;
  align-items: center;
}

.user-thumbnails::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.user-thumbnails .user-avatar,
.user-thumbnails .more-users {
  margin-bottom: 1px;
  margin-top: 1px;
}

.user-thumbnails img {
  height: 30px;
  width: 30px;
  margin-right: 2px;
  padding: 0;
  row-gap: 4px;
}

.user-thumbnails .custom-tooltip
{
  margin-right: -4px;

  &:last-child {
    //margin-left: 0;
  }
}

.user-thumbnails .more-users {
  margin-left: 8px;
}
