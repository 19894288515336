/* Split the screen in half */
.split {
    height: 100%;
    width: 70%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
    border-left: solid;
    height: 91%;
  }
.split.left{
    width: 30%;
}
  /* Control the left side */
  .left {
    left: 0;
  }

  /* Control the right side */
  .right {
    right: 0;
  }

  /* If you want the content centered horizontally and vertically */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .border-right{
    border-right: solid;
  }

  .pr-3{
      padding-right: 3rem !important;
  }

  .pl-3{
    padding-left:  3rem !important;
  }

.border-right {
  border-right: 1px solid #ddd;
}

.border-left {
  border-left: 1px solid #ddd;
}
