.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}

.login-page__box {
  background-color: #f4f1ec;
  padding: 1rem;
  min-height: 100vh;
  min-height: 100dvh;
  height: 100%;

  &.mobile-hide-logo{
    .layout__login-logo{
      display: none;
    }

    @media (min-width: $medium-breakpoint) {
      .layout__login-logo{
        display: block;
      }
    }
  }
}

.login-page__info {
  padding: 2rem 0rem;
  min-height: 100vh;
  min-height: 100dvh;
  height: 100%;
  color: white;
  background-size: 100% 100%;
  font-weight: 600;

  *{
    margin-bottom: 1rem;

    @media (min-width: $medium-breakpoint) {
      margin-bottom: 1.5rem;
    }
  }

  h1{
    font-weight: 900;
    font-size: 46px;

    @media (max-width: $medium-breakpoint) {
      font-size: 40px;
      margin-bottom: 0.25rem;
    }
  }

  p{
    width: 70%;
    font-size: 18px;
  }

  .login-page__info__container{
    width: 80%;
    max-height: 100%;
    margin-bottom: 0;

    img{
      display: block;
      margin-bottom: 1.75rem;
    }

    @media (min-width: $medium-breakpoint) {
      img{
        display: none;
      }
    }

    .login-page__account-features{
      border: 1px solid white;
      border-radius: 20px;
      width: 250px;
      height: 250px;

      li:not(:last-child){
        margin-bottom: 0.9rem;
      }
    }

    .login-page__pages{

      li{
        float: left;
        margin-right: 0.5rem;
        font-size: 13px;
      }
    }
  }
}

.login-page__form {
  label{
    color: #727580;
    font-weight: 500;
  }

  input{
    color: #727580 !important;
  }
}

@media (min-width: 768px) {
  .login-page__form{
    margin-right: 3rem;
    margin-left: 3rem;
  }
}


.login_message{
  font-weight: 500;

  &.success{
    color: #3dac45;
  }
}

.cant_login-links{
  font-weight: 500;

  a{
    &:hover{
      text-decoration: underline;
    }
  }
}


.login-form__button-list {
  line-height: 1.8;
  list-style-type: none;

  li {
    text-align: center;
    color: #727580;
    font-weight: 500;

    &:hover{
      text-decoration: underline;
    }

    a {
      color: #727580;
      font-weight: 500;
    }
  }
}

.login_error{
  color: #ff3342;
  font-weight: 500;
  min-height: 24px;
}
