button .button-icon {
  color: inherit;
}

.button-icon {
  height: 1.1rem;
  width: 1rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}

button .button-icon--unhover{
  display: flex;
}

button .button-icon--hover {
  display: none;
}

button:hover .button-icon--hover {
  display: flex;
}

button:hover .button-icon--unhover {
  display: none;
}
