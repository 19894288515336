.user_profile-banner-container{
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
}

.user_profile-banner{
  height: 240px;
  //background-color: purple;
  position: relative;

  &:hover{
    button{
      display: block !important;
    }
  }

  button{
    font-size: 12px;
    font-weight: 500;
    font-family: "Inter";
    text-align: center;
    display: none;
    border-radius: 5px;
    width: 101px;
    height: 36px;
    border-color: rgba(247,247,251, 0.3) !important;
    background-color: rgba(247,247,251, 0.3) !important;
    color: #ffffff;

    &:hover{
      background-color: rgba(237,238,247, 0.5) !important;
      border-color: rgba(237,238,247, 0.5) !important;
    }
  }

  .btn-edit-banner{
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .btn-save-banner{
    background-color: #fe543d !important;
    border-color: #fe543d !important;
    position: absolute;
    bottom: 10px;
    right: 10px;

    &:hover{
      background-color: #fe6f5d !important;
      border-color: #fe6f5d !important;
    }
  }

  .btn-cancel-banner{
    background-color: rgba(247,247,251, 0.3) !important;
    border-color: rgba(247,247,251, 0.3) !important;
    position: absolute;
    bottom: 10px;
    right: 120px;

    &:hover{
      background-color: rgba(247,247,251, 0.5) !important;
      border-color: rgba(247,247,251, 0.5) !important;
    }
  }
}

.user_profile-details-container{
  text-align: center;
  min-height: 92px;

  .avatar-container{
    position: relative;
    height: 50px;
    width: 100%;
    margin-bottom: 20px;

    .user-avatar, img{
      position: absolute;
      top: -50px;
      left: calc(50% - 60px);
    }
  }

  .user_profile-details-name{
    font-size: 12px;
    margin-left: 15px;

    h1{
      font-size: 20px !important;
      font-family: 'Inter';
      font-weight: 700;
    }
  }

  .user_profile-details-roles {
    text-transform: capitalize;
  }
}

.user_profile-activities-container{
  background-color: white;
  border-radius: 5px;

  .user_profile-activities-header{
    border-bottom: 3px #f4f1ec solid;

    .user_profile-activities-title{
      font-size: 12px;
      color: #727580;
      font-weight: 700;
      text-align: center;
      width: 79px;
      height: 36px;
      border-radius: 5px;
      background-color: #f7f7fb;
    }
  }

  .infinite-scroll-component__outerdiv{
    height: calc(100% - 60px);
  }

  .user_profile-activities-list{
    list-style: none;

    .activity-item{

      &:hover{
        background-color: #f7f7fb;
      }

      .activity-avatar-container{}

      .activity-description-container{
        font-size: 10px;
        color: #2b2f3f;
        font-weight: 400;

        .activity-name{}

        .activity-description{}
      }

      .activity-time_details-container{
        font-size: 8px;
        color: #727580;
        font-weight: 300;
        color: #40465d;
        letter-spacing: 0.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        .activity-timestamp{}

        .workspace-link{

           &:hover{
             text-decoration: underline;
             cursor: pointer;
           }
        }
      }
    }
  }
}

@media (min-width: $medium-breakpoint) {
  .user_profile-banner-container{
  }

  .user_profile-details-container{
    text-align: left;

    .avatar-container{
      width: 150px;

      .user-avatar{
        left: 20px;
      }
    }
  }
}
