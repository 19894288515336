@import '../variables';

.tui-full-calendar-layout .content-label {
}

.mobile-calendar_container{
  .toastui-calendar-grid-cell-date{

  }

  .toastui-calendar-weekday-grid{
    border: none !important;
  }

  .toastui-calendar-daygrid-cell{
    border-left: 0;
  }

  .toastui-calendar-weekday-grid-date-decorator{
    background-color: $color-brand !important;
  }

  .toastui-calendar-grid-cell-more-events{

  }
}

.react-calendar{
  background: none;
  width: 100%;
  border: none !important;
  padding-bottom: 1rem;
  background-color: white;
  border-bottom: 1px solid #D9D9D9 !important;

  .react-calendar__month-view__weekdays{
    border-bottom: 1px solid #E9EAF5;
    color: #898A8D;
    font-size: 10px;
  }

  .react-calendar__month-view__days__day{
    font-size: 14px;
    color: black;
    font-weight: 500;
    position: relative;
    //background-color: transparent !important;

    &:hover{
      background-color: #e6e6e6 !important;
    }

    abbr{
      height: 28px;
      width: 28px;
      display: inline-block;
      line-height: 28px;
    }

    &.has-events::after{
      content: "";
      position: absolute;
      height: 8px;
      bottom: 0px;
      width: 8px;
      background-color: #8D8E9C;
      border-radius: 4px;
      left: calc(50% - 4px);
    }
  }

  .react-calendar__navigation{
    button{
      color: black !important;
    }

    .react-calendar__navigation__arrow{
      font-size: 20px;
    }
  }

  .react-calendar__tile--now{
    background-color: transparent !important;


    &.react-calendar__year-view__months__month{
      abbr{
        padding: 5px;
      }
    }

    abbr{
      background-color: $color-brand;
      border-radius: 14px;
      color: white;
    }
  }

  .react-calendar__tile--active{
    background-color: transparent !important;
    color: $color-brand;
  }
}

.mobile-calendar_cards{

  .mobile-calendar_card{
    position: relative;
    background-color: #fff;
    padding: 8px;
    border-radius: 5px;

    .mobile-calendar_card-title{
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      height: 40px;
      line-height: 40px;
    }

    .mobile-calendar_card-dueDate{
      font-size: 12px;
      text-align: right;
      width: 60px;
    }

    .mobile-calendar_card-info{
      width: calc(100% - 60px);
    }

    .tag{
      margin-bottom: 5.5px;
    }
  }

  .mobile-calendar_card.add-card_button{
    min-height: auto;
    background-color: #f4f2f9 !important;
    font-size: 14px;
    color: #8D8E9D !important;

    .button-content-add{
      background: none !important;
      color: #8D8E9D !important;
      border: none;
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      text-align: left;
    }
  }
}
