$color-brand: #fe543d;
$color-primary: $color-brand;
$color-secondary: #ffc05f;
$color-success: #2ECDAB;
$color-danger: #dc3545;
$color-dark: black;
$color-light: red;
$color-tool-bg: #F7F6FB;

$body-color: #2b2f3f;

$input-border-color: transparent;
$input-focus-bg: #fff;
$input-focus-border-color: #ddd;

$form-input-border: #e8e8e8;

$large-breakpoint: 992px;
$medium-breakpoint: 768px;

$inline-edit-border-color: #ddd;

$theme-colors: (
  "dark": $color-dark,
  "primary": $color-primary,
  "alert-danger" : $color-danger,
  "secondary" : $color-secondary,
  "danger" : $color-danger,
  "bg-light" : $color-light,
  "brand" : $color-brand
);

$font-family-sans-serif: Inter, Arial, Helvetica, sans-serif;

$link-color: map-get($theme-colors, "primary");
$link-decoration: none;

$h1-font-size: 2.8em;
$h2-font-size: 1.6em;
$h3-font-size: 1.4em;
$h4-font-size: 1.2em;
$h5-font-size: 1.1em;
$h6-font-size: 1em;

$btn-border-radius: 5px;
$btn-font-weight: 500;
$btn-hover-bg-shade-amount: 25%;
$btn-hover-bg-tint-amount: 25%;

$dropdown-spacer: 0;
$dropdown-min-width: 5rem;
$dropdown-border-radius: 0;
$dropdown-inner-border-radius: 0;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 0.5em;
$dropdown-link-hover-bg: #eee;
$dropdown-link-hover-color: #212529;
$dropdown-link-active-bg: #eee;
$dropdown-link-active-color: #212529;

$modal-content-bg: #e2e2e2;
$modal-inner-padding: 2rem;
$modal-header-padding-y: 0.5rem;
$modal-header-padding-x: 2rem;

$breadcrumb-font-size: 0.9rem;
$breadcrumb-margin-bottom: 1.5rem;
$breadcrumb-divider-color: #bbb;
$breadcrumb-active-color: #777;
$breadcrumb-inactive-color: #AAA;
$breadcrumb-link-color: #AAA;
$breadcrumb-divider: quote("»");

$nav-link-font-size: 14px;
