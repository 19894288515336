@import './variables';

$color-brand: #fe543d;

.content__stage-dropdown,
.content__reminder-dropdown  {

  .dropdown-toggle,
  .dropdown-toggle:hover,
  .dropdown-toggle:focus{
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.9rem;
    font-weight: normal;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    min-width: 184px;
    padding-top: 4px;
    padding-bottom: 4px;
    left: -9px !important;
  }

  .dropdown-item {
    font-size: 0.9rem;
    font-weight: 500;
    padding-left: 10px !important;
    padding-right: 18px !important;
  }
}

@media (min-width: $large-breakpoint) {

  .content__details-detail {
    max-width: 200px;
  }
}

.btn.content-list__toggle {
  position: absolute;
  z-index: 2;
  top: 50%;
  margin-top: -1rem;
  border-radius: 50%;
  background-color: #e9e8f5;
  border-color: #aaa;
  color: #aaa !important;

  &:focus,
  &:hover {
    background-color: #e9e8f5;
    border-color: #aaa;
    color: #aaa !important;
  }
}

.content-group__chat-panel {
  //display: flex;
  //flex-direction: column;
  //max-height: 77vh;
  //height: 100%;
  //max-height: calc(100vh - 4rem);
  position: relative;
}

.container-page_container{
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;

  .btn-close{
    position: absolute;
    top: 6px;
    right: 10px;
    z-index: 99;
    opacity: 1;
  }

  .container-page_modal-holder{
    position: relative;
    padding: 0;
    background-color: #ededed;
    border-radius: 6px;
    margin-top: 2rem;
    height: calc(100vh - 2rem);
    height: calc(100dvh - 2rem);

    @media (min-width: $large-breakpoint){
      padding-bottom: 0;
    }
  }

  .current_content_group-container{

    @media (min-width: $large-breakpoint){
      flex: 1;
      min-height: 0;
      display: flex;
    }

    h3 {
      font-size: 1.1rem;
      font-weight: 600;
    }

    label{
      font-size: 0.8rem;
      color: #2b2f3f;
      line-height: 16px;
    }

    .detail{
      border-radius: 5px;
      font-size: 0.9rem;
      min-height: 32px;
      position: relative;
      border: 1px solid transparent;

      &.done-box{
        padding: 15px !important;
        border-radius: 5px;
        border: 1px solid #68C5AA;

        label{
          color: #68C5AA !important;
          font-weight: bold;
        }

        .form-check-input{
          border: 1px solid #68C5AA;
        }
      }

      &.active{
        border: 1px solid #FE543D;
      }

      &:not(.bg-transparent){
        background-color: white;
        padding: 6px;
      }

      &:not(.bg-transparent):not(.mark_as_complete-btn-container){
        padding-right: 15px;
      }

      .dropdown-icon{
        position: absolute;
        right: 5px;
        top: 35%;
      }
    }

    .project-label,
    .content-label {
    }

    .user-thumbnails img {
      height: 27px;
      width: 27px;
      padding: 0;
    }
  }

  .current_content_group-container__stage-select {
    font-size: 13px;
    width: auto;
  }

  .current_content_group-container-grid {


    @media (min-width: $large-breakpoint) {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
      max-width: 100%;
    }
  }

  .contents-container {
    flex: 1;
    overflow-y: auto;
    width: initial !important;
    max-height: calc(100vh - 6rem);
    max-height: calc(100dvh - 6rem);

    @media (min-width: $large-breakpoint) {
      padding-left: 3rem;
      padding-right: 3rem;
      padding-bottom: 0;
      max-height: inherit;
    }
  }

  .flex_container {
    display: flex;
    max-height: 100vh;
    max-height: 100dvh;
    flex-direction: column
  }
}

.content__details-container{

  .content__details-detail{
    width: 100%;
    font-size: 14px !important;

    label{
      font-size: 12px;
      font-weight: bold;
    }
  }

  .content__details-detail-input{
    background: #F5F5FF;
    border-radius: 3px;
    width: 21px;
    text-align: center;

    &:not(.minimize){
      width: 100%;
      text-align: inherit;
    }

    input{
      margin-right: 0.5rem;
    }
  }
}

#current-container{
  overflow: visible;

  .empty-content_container{
    position: relative;
    padding: 0;

    .ck-editor__top {
      overflow: hidden;
    }

    button,
    .file_upload_p{
      background-color: #fff;
      color: #8C8B9A !important;
      border: none !important;
      margin-bottom: 0;
      display: inline-flex;
      cursor: pointer;
      align-items: center;
      padding: 5px 7px 5px 5px;
      border-radius: 3px;
      font-size: 14px;
    }

    input,
    textarea {
      border: none;
      background: #fff;
      padding: 0;
      color: #000 !important;
    }

    input {
      padding: 0.8rem 0.8rem;
    }

    textarea {
      padding: 0.8rem;
    }

    input[type=text] {
      height: 24px;
    }

    .ck.ck-reset{
      width: 100% !important;
      margin-right: 0 !important;
    }
  }
}

#content_list-container,
#current-container,
#chat_message-container{
  transition: all 0.3s ease;
}

#chat_message-container{
  width: 400px;
  //padding-bottom: 108px;

  .content-group__chat-panel{
    height: inherit;
    //display: flex;
    //flex-direction: column;
    //max-height: calc(100vh - 4rem);
    position: relative;
  }

  @media (min-width: $large-breakpoint) {
    .content-group__chat-panel{
      height: initial;
      display: flex;
      flex-direction: column;
      max-height: calc(100vh - 4rem);
      max-height: calc(100dvh - 4rem);
      position: relative;
    }
  }

  @media (max-width: $large-breakpoint) {
    .content-group__chat-panel{

    }
  }


  @media (min-width: $large-breakpoint) {
    padding-bottom: 0 !important;
  }
}

/* Mobile */
@media (max-width: $large-breakpoint) {
  #content_list-container{
    i.fa.fa-long-arrow-left,
    i.fa.fa-long-arrow-right{
      display: none;
    }

    span{
      display: block;
    }
  }

  #current-container{
    margin-bottom: 3rem;
  }

  #chat_message-container{
    width: 100%;
    height: calc(100vh - 140px);
    height: calc(100dvh - 140px);

    .toggle-btn{
      height: 30px;

      button{
        float: none;
      }
    }

    i.fa.fa-long-arrow-right,
    i.fa.fa-long-arrow-left{
      display: none;
    }
  }

  #chat_message-container.current_content_group-container {
    height: calc(100vh - 0px) !important;
    height: calc(100dvh - 0px) !important;
  }

}

#content_list-container {
  .card-image {
    height: initial;
    padding: 4px;
  }

  .content-body {
    font-size: 0.9rem;
  }
}

@media (min-width: $large-breakpoint) {
  .container-page_container  {
    .contents-container{
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }
}

/* Desktop */
@media (min-width: $large-breakpoint){
  #content_list-container{
    width: 200px;

    &.toggle{
      width: 10%;

      .left-full-height{

        .filter-buttons{
          visibility: hidden;
        }

        .content-card{

          .card_header{
            display: none;
          }

          .card-image{
            height: auto;
          }

          .content-body{
            display: none;
          }

          .content-feature{
            display: none !important;
          }
        }
      }

      .fa.fa-long-arrow-right{
        display: block;
      }

      .fa.fa-long-arrow-left{
        display: none;
      }
    }

    .fa.fa-long-arrow-right{
      display: none;
    }

    .fa.fa-long-arrow-left{
      display: block;
    }

    span{
      display: none;
    }
  }

  #current-container{
    width: 100%;
  }

  #chat_message-container{
    &.toggle{
      width: 10%;

      .chatbtn-group:not(.submitbtn-group){
        display: none !important;
      }

      .fa.fa-long-arrow-right{
        display: none;
      }

      .fa.fa-long-arrow-left{
        display: block;
      }

      .chat_section{
        width: 100%;
      }

      form{
        display: none;
      }
    }

    .toggle-btn{
      height: 30px;
    }

    .fa.fa-long-arrow-right{
      display: block;
    }

    .fa.fa-long-arrow-left{
      display: none;
    }

    //width: 40%;
  }
}

.chatbtn-group{
  .tabs {
    ul{
      li:first-child {
        border-radius: 5px 0 0 5px;
      }

      li:last-child {
        border-radius: 0 5px 5px 0;
      }

      li{
        width: 50%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        text-align: center;
        border: 1px solid #ccc;
      }

      li:not(.active):hover{
        background-color: #e9eaf5;
      }

      li.active{
        background-color: #14D2E4;
        border-color: #14D2E4;
      }

      li {
        svg{
          fill: #8d8e9d;
        }
      }

      li.active{
        svg{
          fill: white;
        }
      }

      li.active:hover {
        background-color: lighten(#14D2E4, 15%);
      }
    }
  }
}

.custom_tabs {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.custom_tabs li {
  cursor: pointer;
  width: 50%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
}

.tab_active {
  color: #fff !important;
  background-color: #14D3E5;
}

.custom_col_8 {
  padding-top: 1%;
  padding-bottom: 1%;
}

.custom_col_4 {
  display: flex;
  max-height: 100%;
  padding-top: 1%;
  padding-bottom: 1%;
}

@media (min-width: 992px) {
  .custom_col_8 {
    padding-right: 0 !important;
    width: 72% !important;

    .current_content_group-container{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .custom_col_4 {
    padding-left: 0 !important;
    width: 28% !important;


    #chat_message-container{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

}

.window_content_popup {
  height: calc(100vh - 2rem);
  height: calc(100dvh - 2rem);
  padding-left: 2rem;
  padding-right: 2rem
}

.mobile_content_popup {
  padding-left: 0rem;
  padding-right: 0rem;


  @media (max-width: $large-breakpoint) {
    margin-top: 0!important;
    height: 100vh !important;
    height: 100dvh !important;
    border-radius: 0 !important;
  }
}

.mobile_container_page {
  position: fixed !important;
  z-index: 1024;
  overflow: auto;


  .btn-close{
    filter: none;
  }
}

.content_inline_edit input[type=text] {
  border: 1px;
  padding-left: 0px;
  border-radius: 10px
}

.content_inline_edit input:focus {
  background-color: #E9E8F5;
  padding: 10px;
  border: 1px solid #00FFFF !important;
}

.form-control-custom {
  width: 100%;
  font-size: 1rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid transparent;
  min-height: 18px;
  max-height: 100px;
  margin-top: 8px;
  padding: 10px;
  border-radius: 0.25rem;
}

.form-control-custom:hover {
  border: 1px solid transparent;
}

.file_upload_content--empty .file_upload_replace:hover {
  color: darken($inline-edit-border-color, 50%);
  border-color: darken($inline-edit-border-color, 12%);
  background-color: lighten($inline-edit-border-color, 8%);
}

.file_upload_content--empty .file_upload_replace {
  background-color: #e9e8f5;
  color: #222;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  border: 1px solid #FE543D;
  color: darken($inline-edit-border-color, 30%);
  background-color: lighten($inline-edit-border-color, 20%);
}

.file_upload_content--empty {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.file_upload_content--empty .file_upload_replace {
  background-color: #e9e8f5;
  color: #222;
  padding: 2px 5px;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  font-size: 15px;
}

.file_upload_content {
  position: relative;
  border: 1px solid transparent;
}

.file_upload_content:hover {
  border: 1px solid $inline-edit-border-color;
  background-color: lighten($inline-edit-border-color, 8%);
}

.input_validation {
  color: red;
  font-size: 12px;
  position: absolute;
}

.custom_width_modal .modal-dialog {
  max-width: 1000px;
}

.custom_width_modal .modal-header {
  padding: 0.8rem 1rem;
  border: 0.5px solid #eee;
}

.custom_width_modal .modal-title {
  font-size: 15px;
}

.custom_width_modal .form-control {
  font-size: 13px;
}

.custom-top {
  padding-top: 35px;
}

#chat_message-container .current_content_group-container {
  max-height: calc(100vh - 61px) !important;
  max-height: calc(100dvh - 61px) !important;
}

.assigned_user_list {
  background: #ffffff;
  position: absolute;
  right: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.2s ease,
  transform 0.2s ease,
  visibility 0.2s;
  z-index: 1024
}

#new_assigned_user_list {
  width: 260px;
  left: 0;
  right: 0;
}

.assigned_user_list.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.assigned_user_list ul {
  list-style: none;
  padding: 5px;
  margin: 0;
}

.assigned_user_list li {
  padding: 3px 3px;
  cursor: pointer;
}

.user_email {
  color: #333333;
  font-size: 13px;
  display: block;
  padding-left: 5px
}

.user_row {
  padding: 5px 5px;
  border-radius: 5px;
}

.highlight_menu .user_row {
  background-color: #e9ecefd1;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0
}

#input_validaion{
  position:relative;
}

.description-section {
  position: relative;
  margin-bottom: 3rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;

  @media (min-width: $large-breakpoint) {
    margin-left: 0;
    margin-right: 0;
  }


  &:hover .button-group {
    display: block;
  }

  textarea,
  input[type=text] {
    margin-bottom: 4rem;
  }

  .button-group {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
  }
}

.description-section__bottom {
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 100%;
}

.disapprove_drop_down.dropdown-menu {
  background-color: $color-brand !important;

  &.disapprove_drop_down--disabled {
    background-color: lighten($color-brand, 10%) !important;
  }

  a{
    background-color: $color-brand  !important;
  }
}

.content_attachment_img {
  width: 50px;
  height: 50px;
}

@media (min-width: $large-breakpoint) {
  .content_attachment_img {
    margin-left: -10px;
  }
}

.assigned_users_div {
  position: relative;
}

@media (min-width: 300px) and (max-width: 767px) {
  #new_assigned_user_list {
    width: 100%
  }
  .assigned_users_div {
    position: initial;
  }
}

.link_color, .link_color:hover {
  color: #0d6efd
}

.user_tag {
  color: #027EB4;
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
}

.contentedit_textarea {
  overflow: auto;
  width: 100%;
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 16px;
  border-radius: 5px;
  max-height: 80px;

  @media (min-width: $large-breakpoint) {
    max-height: 250px;
    font-size: 14px;
  }
}

.contentedit_textarea:focus {
  //border: 1px solid #ddd
}

.chatbox-container{
  background: #fff;
  padding: 10px;
  border-radius: 5px
}

.show_content_notification {
  position: fixed !important;
  left: 0;
  z-index: 1024;
}

.attachment_list-container{
  position: relative;

  .dropzone-line{
    position: absolute;
    height: 100%;
    bottom: 10px;
    left: 0;
    border-bottom: 2px solid gray;
    animation: 2.5s fadeIn;
    visibility: hidden;
    animation-fill-mode: forwards;
  }
}

.attachment_list-carousel{
  overflow: auto;
  display: flex;
  margin-left: 1rem;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb:horizontal{
    background: #8D8E9D;
    border-radius: 10px;
  }

  &.dragged{
    .dropzone-line{
      visibility: visible !important;
    }
  }

  .attachment_list-carousel-item{
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0);

    &.active{
      border: 2px solid #fe543d !important;
    }

    &:hover{
      border: 2px solid #8D8E9D;
    }
  }
}

.content__details-sidebar{
  padding-bottom: 0;

  @media (max-width: $large-breakpoint) {
    //padding-bottom: calc(100px + 1.5rem);
  }
}

.content-group_settings{

  @media (min-width: $large-breakpoint) {
    margin-top: 4rem;
  }

  button.dropdown-toggle{
    height: 30px;
    width: 30px;
    background-color: white;
    border: none;
  }

  i{
    color: #2b2f3f;
  }
}

.description_button-group{
  .btn-primary:not(:disabled):hover{
    filter: opacity(70%);
  }

  .btn-primary{
    background-color: $color-success !important;
    border-color: $color-success !important;
  }

  .btn-default:not(:disabled):hover{
    background-color: lightgrey;
  }
}

.dropdown-toggle-split.btn{
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
}

.content__details-sidebar_list{
  overflow-y: auto;
  //padding-bottom: 100px;
  max-height: calc(100vh - 120px);
  max-height: calc(100dvh - 120px);

  @media (min-width: $large-breakpoint) {
    padding-bottom: 0;
    max-height: none;
  }
}

.custom-editor{
  width: 100%;
}

.mark_as_complete-btn-container{
  padding: 6px !important;
  border: 1px solid white;

  &.completed{
    background: rgba(98, 213, 138, 1) !important;
    border: 1px solid rgba(44, 165, 86, 1);

    .checkbox-text{
      color: white !important;
    }
  }

  .checkbox-text{
    font-size: 0.9rem;
  }

  .mark_as_complete-btn{
    width: 100%;
    height: 100%;
    font-size: 16px !important;
    min-height: 32px;
    border: 1px solid transparent;

    .mark_as_complete-btn-check{
      position: relative;

      svg{
        position: absolute;
        fill: white;
        width: 0.9rem;
        top: 0.3rem;
        height: 0.9rem;
        right: 0;
      }
    }

    input{
      height: 0.9em;
      width: 0.9em;
      -webkit-appearance: none;
      appearance: none;
      /* creating a custom design */
      border-radius: 0.15em;
      outline: none;
      border: 0.15em solid rgba(95, 99, 104, 1);
      cursor: pointer;

      &.checked{
        border: 0.15em solid white;
      }

      &.checked {
        background-color: rgba(98, 213, 138, 1);
        position: relative;
      }
    }

    .checkbox{
      height: 0.9rem;
      width: 0.9rem;

      background-color: #eee;
    }
  }
}

.card_title-height{
  @media (max-width: $large-breakpoint) {
    max-height: 58px;
  }
}
