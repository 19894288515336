.workspace_card{

  span{
    display: inline-block;
    min-width: 2rem;
    font-weight: 700;
    color: #fe543d;
    font-size: 18px;
  }

  .workspace_card-details{
    .workspace_card-organization_title{
      color: #727580;
      padding: 0.5rem 1.3rem;
    }
  }

  .dropdown{
    //position: absolute;
    //bottom: 10px;
    //right: 10px;
  }


  .workspace_card-image_container{
    width: 140px;
    height: 100%;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

  }
}

.workspace_button-group{
  h2 {
    font-weight: 700;
    color: #fe543d;
  }

  .btn {
    border: none;
    font-size: 14px;

    &.btn-primary{
      color: #fff !important;
      background: #fe543d !important;
    }

    &.btn-light{
      background-color: #f7f7fb;
      color: #727580;
    }
  }


  select {
    min-width: 100px;
    padding-left: 1.8rem;
    padding-right: 0;
    font-size: 14px;
  }

  select{
    background-color: transparent !important;
    color: #8D8E9D !important;
    border: none;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='currentColor'/></g></svg>") !important;
    background-position: left 8px top 60%;
    padding: 0.375rem 0.75rem 0.375rem 1.55rem;
    min-width: 180px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 991.98px) {
  .workspace_button-group{
    .d-flex{
      display: block !important;
    }
  }
}


// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

  .workspace_card{

    .workspace_card-image_container{
      width: 100%;
      height: 400px;
      margin-top: 40px;
      border-radius: 0;
    }
  }

}

.inline_edit_div .inline_input {
  width: 90%;
  height: 35px;
  font-size: 12px;
  padding: 0px 5px;
  border: 0px;
  border-radius: 0px;
  background: transparent;
  text-align: center;
}

.inline_edit_div .inline_datepicker {
  width: 100%;
  height: 35px;
  font-size: 12px;
  padding: 0px 5px;
  border: 1px solid #FE543D;
  border-radius: 0px;
  text-align: center;
}


.inline_edit_div .form-control:hover {
  background: transparent;
}

.focus_edit_input, .focus_edit_input:hover {
  border: 1px solid #FE543D;
  border-radius: 0.25rem
}

.edit_icon {
  font-size: 8px;
  margin-left: 8px;
  cursor: pointer;
}

.approval-icon {
  padding-left: 0px;
}

.approval-icon fa {
  font-size: 12px;
}

.select_inline_div .form-control {
  width: 100%;
  height: 29px;
  font-size: 12px;
  padding: 3px 6px;
  letter-spacing: 0.5px;
  border: 0px;
  border-radius: 5px;
  background: transparent;
  text-align: center
}

.organization_title{
  color: #fe543d;
  border: 1px solid transparent;
  font-size: 1.2rem;
}

.personal_workspace-progress{
  font-size: 10px;
  color: #A1A3AE;
  max-width: 120px;

  .progress{
    height: 0.5em;
  }
}

.workspace-side_details{

  @media (min-width: $large-breakpoint){
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.workspace_card{
  .workspace_card-details{
    font-size: 15px;

    .workspace_card-details__title{
      font-size: 1rem
    }
  }
}
