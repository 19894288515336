.tag,
.tag-notification_avatar{
  height: 21px;
  border-radius: 30px;
  font-size: 10px;
  width: max-content;
  font-weight: bold;

  &.tag-large{
    height: 31px;
    padding: 5px;
  }

  svg{
    margin-top: 4px;
    margin: 5px;
  }

  span{
    margin-right: 5px;
    line-height: 14px;
  }

  &.completed-tag{
    background-color: rgba(98, 213, 138, 1);
    color: rgba(6, 101, 39, 1);
    border: 1px solid rgba(44, 165, 86, 1);

    svg{
      fill: rgba(6, 101, 39, 1) !important;
    }

    &.tag-large{
    }
  }

  &.past-due{
    color: white;
    background: #A9A9A9;
    border: 1px solid #696969;

    svg{
      fill: white;
    }

    &.past-due-in-2-days{
      background: rgba(255, 235, 153, 1);
      border: 1px solid rgba(255, 204, 0, 1);
      color: rgba(153, 122, 0, 1);

      svg{
        fill: rgba(153, 122, 0, 1) !important;
      }
    }

    &.past-due-in-0-days,
    &.past-due-in--1-days{
      background: rgba(255, 160, 158, 1);
      border: 1px solid rgba(255, 60, 56, 1);
      color: rgba(209, 4, 0, 1);

      svg{
        fill: rgba(209, 4, 0, 1) !important;
      }
    }

    &.past-due-in-1-days{
      background: rgba(255, 193, 153, 1);
      border: 1px solid rgba(255, 140, 66, 1);
      color: rgba(219, 86, 0, 1);

      svg{
        fill: rgba(219, 86, 0, 1) !important;
      }
    }
  }
}

.tag-notification_avatar{
  height: 48px;
  width: 48px;
  border-radius: 50%;

  svg{
    height: 24px;
    width: 24px;
  }

  @media (min-width: $large-breakpoint) {
    height: 32px;
    width: 32px;

    svg{
      height: 14px;
      width: 14px;
    }
  }
}
