.MuiInputBase-root{
  line-height: inherit !important;
}

.MuiInputLabel-formControl {
  display: none !important;
}
#mui-5-label {
  display: none;
}

.MuiInputBase-input {
  padding: 0.25rem !important;
  font-size: 1rem !important;
  height: inherit !important;


  @media (min-width: $large-breakpoint) {
    font-size: 0.9rem !important;
  }
}

.MuiOutlinedInput-notchedOutline legend {
  display: none;
}

.MuiOutlinedInput-notchedOutline{
  border: none !important;
}
