@import '../variables';

.workspace_page-title:not(.mobile-container){
  .workspace_search-bar_container{
    position: relative;

    .workspace_search-bar{
      width: 288px;
    }
  }
}

.workspace_search-bar_container {
  @media (min-width: $large-breakpoint) {
    margin-left: 15px;
  }

  .workspace_search-bar{
    height: 34px;
    border-radius: 4px;
    background-color: #f7f7fb;
    border: 0px solid #000000;
    font-size: 14px;
    color: #727580;
    display: flex;

    &:focus-within{
      filter: drop-shadow(0px 10px 5px rgba(142,145,158,0.40));
      background-color: #ffffff;
    }

    &.active{
      background-color: #ffffff;
    }

    svg{
      fill: #727580;
      height: 18px;
      width: 18px;
    }

    input{
      background-color: inherit;
      font-weight: 400;
      font-family: "Inter";
      border: none;
      width: 100%;
      color: #2b2f3f;
      font-size: 1rem;

      @media (min-width: $large-breakpoint) {
        font-size: 14px;
      }
    }

    .workspace_search-bar_close-button{
      border-radius: 10px;
      background-color: #f6f7fb;
      height: 22px;
      width: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.workspace-search-result_container{
  position: absolute;
  z-index: 1024;
  max-height: 75vh;
  top: 39px;
  width: 435px;
  border-radius: 5px;
  filter: drop-shadow(0px 10px 5px rgba(142,145,158,0.40));
  background-color: #ffffff;
  border: 1px solid rgba(244,241,236,0.5);
  font-size: 14px;

  li:nth-of-type(1n+11) {
    display: none !important;
  }

  .workspace-search-result_item{
    height: 40px;
    cursor: pointer;
    font-size: 14px;
    color: #727580;
    font-weight: 500;
    font-family: "Inter";

    @media (min-width: $large-breakpoint) {
      height: 36px;
    }

    &:hover{
      background-color: rgba(233,234,245, 0.4);

      .content-details_close-button{
        background-color: #ffffff;
      }
    }

    &.searched{
      .content-details{
        width: calc(100% - 20px);
        margin-right: 10px;
      }
    }

    svg{
      fill: #727580;
      height: 20px;
      width: 20px;
    }

    .content_image{
      object-fit: cover;
      overflow: hidden;

      img{
        height: 100px;
        width: 100px;
      }
    }

    .content-details{
      width: 90%;

      .content-details_title{
        font-family: 'Inter';
        font-weight: 500;

        em{
          font-weight: 700;
          font-style: normal;
          color: #2b2f30;
          font-family: 'Inter';
        }
      }

      .content-details_stage-name,
      .content-details_updated-at{
        color: #8D8E9D;
        font-size: 0.9rem;
      }
    }

    .content-details_close-button{
      border-radius: 10px;
      background-color: #f6f7fb;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.workspace_search-sort{
  height: 43px;
  width: 43px;
  background-color: white;

  &.active{
    background-color: #fe543d;
    color: white;
  }
}

.workspace_bar-dropdown{

  .dropdown-menu{
    border-radius: 5px;
    overflow: hidden;
    filter: drop-shadow(0px 5px 4px rgba(0,0,0,0.2));
    border: none;
    transform: translate(0px, 54px) !important;

    .dropdown-item{
      width: 309px;
      height: 47px;
      border-radius: 5px;
      background-color: #ffffff;
      font-size: 12px;
      color: #2b2f3f;
      font-weight: 500;
      padding: 0.5rem;
      font-family: "Inter";

      &:hover{
        background-color: #f7f7fb;
      }

      img{
        border-radius: 5px;
      }
    }
  }
}

.workspace_page-title.mobile-container{

  .workspace_search-bar_container{


    .workspace_search-bar{
      width: 100%;
    }
  }

  .workspace-search-result_container{
    left: 0;
    top: 108px;
    width: 100%;
    max-height: calc(100vh - 108px);
    max-height: calc(100dvh - 108px);
    height: calc(100vh - 108px);
    height: calc(100dvh - 108px);
  }

  .dropdown-toggle__text{
    font-size: 16px;
  }
}

.workspace_search-bar_mobile_search_toggle{
  font-size: 14px;

  &.search_close_icon-btn{
    height: 24px;
    width: 24px;
  }

  @media (min-width: $large-breakpoint) {
    display: none;
  }
}
