.workspace_sidebar-accordion{

  .accordion-item{
    border: none;
    background-color: transparent;
  }

  .personal{
    .accordion-button{
      border-radius: 5px !important;
    }
  }

  .accordion-button{
    background-color: transparent;
    box-shadow: none;
    color: #727580;
    font-size: 15px;

    &::after{
      display: none;
    }

    &.collapsed{
      border-radius: 5px !important;
      box-shadow: none;
    }

    &:not(.collapsed){
      color: inherit;
      background-color: #f7f7fb;
      box-shadow: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: #727580;
      font-weight: 700;
    }

    &:hover{
      background-color: #f7f7fb;
    }
  }

  .accordion-collapse.collapse.show{
    background-color: #f7f7fb;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .workspace_sidebar-accordion__workspace_link{
    padding: 1rem 1.25rem;
    border-radius: 5px;
    color: #727580;

    &:hover,
    &.active{
      background-color: #edeef7;
    }
  }

  .accordion-body{
    padding-top: 0;
  }
}

.business_title{
  color: #fe543d;
  padding: 1rem 1.25rem;
  font-size: 1.2rem;
}
