.web_sidebar{
  max-width: 322px !important;
  background: #F5F5F5;

  .offcanvas-header,
  .offcanvas-body{
    padding: 16px;

    .workspace-info_container{

      .workspace-title{

        .workspace-logo{
          height: 36px;
          width: 36px;
          background-color: #D9D9D9;
          border-radius: 5px;
          margin-right: 16px;
        }
      }

      .mobile-button{
        font-size: 14px;

        .mobile-icon{
          height: 18px;
          width: 18px;
          display: inline-block;
          margin-right: 14px;
          font-size: 18px;
          color: #727580;
          text-align: center;
        }

        .mobile-participants_container{
          margin-left: 36px;

          .user-thumbnails{
            display: block;
            margin-top: 5px;
          }
        }
      }
    }

    .workspace_sidebar-accordion-mobile-workspaces{
      font-size: 14px;
      font-weight: 400;

      .workspace_sidebar-accordion-mobile-workspace_item{
        .workspace_sidebar-accordion-mobile-workspace_name{
          overflow-wrap: break-word;
          min-height: 40px;
        }
      }
    }
  }
}

.workspace_sidebar-accordion-mobile{
  .accordion-item{
    border: none;
    background-color: transparent;
  }

  .personal{
    .accordion-button{
      &::after{
        display: none;
      }
    }
  }

  .accordion-button{
    background-color: transparent;
    box-shadow: none;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    padding-left: 0;

    &.collapsed{
      border-radius: 5px !important;
      box-shadow: none;
    }

    &:not(.collapsed){
      color: inherit;
      box-shadow: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:hover{
      //background-color: #f7f7fb;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
  }

  .accordion-body{
    padding-top: 0;
  }
}
