.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  right: 0;
  padding: 5px 10px;
  top: 5px;
  font-size: 15px;
}

.alert .btn-close {
  margin-left: 20px;
  width: 8px;
  height: 8px;
  padding: 2px;
}

.alert.alert-text{
  &.alert-danger{
    color: #ff3342;
  }

  &.alert-success{
    color: #3dac45;
  }

  background-color: transparent;
  font-weight: 500;
  min-height: 24px;
  border: none;
  padding: 0;
  text-align: center;
  display: block;
}
